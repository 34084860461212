import { useState } from 'react';
import { Button, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Eye, EyeSlash } from 'phosphor-react';

import TextField, { type TextFieldProps } from 'src/components/Form/TextField';
import Icon from 'src/components/Icon';

export const PasswordField = (props: Partial<TextFieldProps>) => {
  const [show, setShow] = useState(false);
  const togglePassword = () => setShow(!show);

  return (
    <InputGroup>
      <TextField
        name={props.name || 'password'}
        label={props.label || 'Senha'}
        type={show ? 'text' : 'password'}
      />
      <InputRightElement>
        <Button
          title={show ? 'Ocultar senha' : 'Mostrar senha'}
          onClick={togglePassword}
          mt="16"
          size="sm"
          variant="unstyled"
          justifyContent="center"
          display="flex"
          _hover={{
            background: 'blue.50  ',
          }}
        >
          <Icon
            icon={show ? EyeSlash : Eye}
            color="gray.600"
          />
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
