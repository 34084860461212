import { decode } from 'jsonwebtoken';

interface IDecodedToken {
  id: number;
  PartnerId?: number;
  slug?: string;
  defaultPaymentMethod?: Array<string>;
  email: string;
  accessLevel: string;
  hasProductValueLimit?: boolean;
  partner: {
    id?: number;
    hasProductValueLimit?: boolean;
    name?: string;
    slug?: string;
    defaultPaymentMethod?: Array<string>;
  };
  iat: number;
  exp: number;
}

export const decodeToken = (token: string): IDecodedToken => {
  const decodedToken = decode(token) as IDecodedToken;
  return decodedToken;
};
