import NextLink from 'next/link';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';

type LinkPropsType =
  | ChakraLinkProps
  | {
      href: string;
      children: React.ReactNode;
    };

const Link = (props: LinkPropsType) => {
  const { children, href, ...rest } = props;

  const isExternal: boolean = href?.indexOf('http') === 0;

  return (
    <ChakraLink
      as={isExternal ? 'a' : NextLink}
      href={href}
      {...rest}
    >
      {children}
    </ChakraLink>
  );
};

export default Link;
