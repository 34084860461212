import Joi from 'joi';

export const EmailValidation = Joi.string()
  .email({ tlds: { allow: false } })
  .required()
  .messages({
    'any.required': 'O e-mail é obrigatório',
    'string.empty': 'O e-mail é obrigatório',
    'string.email': 'O e-mail está inválido',
  });

export const LoginSchema = Joi.object({
  email: EmailValidation,
  password: Joi.string().required().messages({
    'any.required': 'A senha é obrigatória',
    'string.empty': 'A senha é obrigatória',
  }),
});
