import { Flex, Image, Stack, useColorModeValue } from '@chakra-ui/react';
import { PropsWithChildren, ReactNode } from 'react';

export const LoginLayout = ({
  children,
}: PropsWithChildren<{ children: ReactNode }>) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const imageFilter = useColorModeValue('none', 'brightness(0) invert(1)');

  return (
    <Stack
      minH="100vh"
      direction={{ base: 'column', md: 'row' }}
      backgroundImage="url(/assets/background-principia.webp)"
      backgroundPosition="bottom"
      bgSize="cover"
    >
      <Flex
        p={{
          base: 4,
          md: 8,
        }}
        flex={1}
        align="center"
        justify="center"
        transform={{ base: 'translateX(0)', md: 'translateX(-20%)' }}
      >
        <Stack
          spacing={4}
          w="full"
          maxW="sm"
          bgColor={bgColor}
          p={{
            base: '4',
            md: '10',
          }}
          borderRadius="md"
          gap={{
            base: 4,
            md: 6,
          }}
        >
          <Image
            src="/assets/principia-logo.svg"
            alt="Logo"
            w="28"
            filter={imageFilter}
          />
          <Stack mt={0}>{children}</Stack>
        </Stack>
      </Flex>
    </Stack>
  );
};
