import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export default function useSessionStorage<T>(
  key: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const isMounted = useRef(false);
  const [value, setValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return defaultValue;
    }

    try {
      const item = window?.sessionStorage.getItem(key) || '';
      return item ? JSON.parse(item) : defaultValue;
    } catch (e) {
      console.error(e);
      return defaultValue;
    }
  });

  useEffect(() => {
    try {
      const item = window?.sessionStorage.getItem(key) || '';
      if (item) {
        setValue(JSON.parse(item));
      }
    } catch (e) {
      console.error(e);
    }
    return () => {
      isMounted.current = false;
    };
  }, [key]);

  useEffect(() => {
    if (isMounted.current) {
      window?.sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      isMounted.current = true;
    }
  }, [key, value]);

  return [value, setValue];
}
