import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { decodeToken } from 'src/utils/token/decodeToken';

export const useToken = () => {
  const router = useRouter();

  const [tokens, setTokens] = useLocalStorage<string[]>('tokens', []);
  const [currentTokenIndex, setCurrentTokenIndex] = useLocalStorage<number>(
    'currentTokenIndex',
    0,
  );

  const currentToken = tokens?.[currentTokenIndex || 0] || '';
  const currentDecodedToken = decodeToken(currentToken);

  const decodedTokens = tokens?.map((token) => {
    const decodedToken = decodeToken(token);
    return decodedToken;
  });

  useEffect(() => {
    const removeInvalidTokens = () => {
      if (!tokens?.length) return;

      const currentTime = Date.now() / 1000;
      const invalidTokens = tokens?.filter(
        (token) => decodeToken(token)?.exp < currentTime,
      );

      if (!invalidTokens?.length) return;

      console.log('Removing invalid tokens');
      invalidTokens?.forEach((token) => removeToken(token));
    };

    removeInvalidTokens();
  }, []);

  const addToken = (token: string) => {
    console.log('Adding token');

    const decodedToken = decodeToken(token);

    const tokenIndex = tokens?.findIndex(
      (t) => decodeToken(t)?.partner?.id === decodedToken?.partner?.id,
    );

    if (tokenIndex !== -1) {
      console.log('Replacing token');
      setTokens([
        ...(tokens?.slice(0, tokenIndex) || []),
        token,
        ...(tokens?.slice(tokenIndex + 1) || []),
      ]);
      setCurrentTokenIndex(tokenIndex);
      return;
    }

    setTokens([...tokens, token]);
    setCurrentTokenIndex(tokens?.length);
  };

  const removeAllTokens = () => {
    console.log('Removing all tokens');
    setTokens([]);
  };

  const removeToken = (token: string) => {
    console.log('Removing token');
    const tokenIndex = tokens?.findIndex((t) => t === token);

    setTokens([
      ...(tokens?.slice(0, tokenIndex) || []),
      ...(tokens?.slice(tokenIndex + 1) || []),
    ]);

    setCurrentTokenIndex(0);
  };

  const removeCurrentToken = () => {
    console.log('Removing current token');

    if (!currentToken) {
      router.push('/');

      return;
    }

    removeToken(currentToken);
  };

  return {
    currentToken,
    currentDecodedToken,
    currentTokenIndex,
    setCurrentTokenIndex,
    tokens,
    decodedTokens,
    addToken,
    removeAllTokens,
    removeCurrentToken,
  };
};
