import { forwardRef } from 'react';
import { useTheme } from '@chakra-ui/react';
import {
  type IconProps as PhosphorIconProps,
  IconWeight,
} from 'phosphor-react';
import { Icon as ChakraIcon } from '@chakra-ui/react';

export interface IconProps {
  icon: React.ForwardRefExoticComponent<
    PhosphorIconProps & React.RefAttributes<SVGSVGElement>
  >;
  color?: string;
  weight?: IconWeight;
  size?: PhosphorIconProps['size'];
}

const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ icon, weight, size, ...props }, ref) => {
    const theme = useTheme();

    const sizes = (theme.fontSizes || {}) as Record<string, string>;
    const iconSize = sizes[size || '1em'];

    return (
      <ChakraIcon
        as={icon}
        color="current"
        weight={weight || 'fill'}
        display="flex"
        boxSize={iconSize}
        role="img"
        ref={ref}
        {...props}
      />
    );
  },
);

Icon.displayName = 'Icon';

export default Icon;
