/* eslint-disable @typescript-eslint/no-unused-vars */
import type { NextPage } from 'next';
import {
  Button,
  Heading,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Link from 'src/components/Link';
import { TextField } from 'src/components/Form';
import { LoginSchema } from 'src/schemas/LoginSchema';
import { PasswordField } from 'src/components/Form/PasswordField';
import { createLogin } from 'src/services/PlatformAPI';
import { useMutation, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import useSessionStorage from 'src/hooks/useSessionStorage';
import { LoginLayout } from 'src/components/Layout/Login';
import { AxiosError } from 'axios';
import { decode } from 'jsonwebtoken';
import { useToken } from 'src/utils/token/useToken';

const Home: NextPage = () => {
  const { addToken } = useToken();

  const router = useRouter();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [_tokenRef, setTokenRef] = useSessionStorage<
    string | number | undefined
  >('token_ref', undefined);
  const [_emailRef, setEmailRef] = useSessionStorage('email_ref', '');

  const methods = useForm({
    mode: 'onChange',
    resolver: joiResolver(LoginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { mutate: login, isLoading: isLoginLoading } = useMutation(
    createLogin,
    {
      onSuccess: async ({ data }) => {
        addToken(data.access_token);

        queryClient.invalidateQueries('accessLevel');

        const tokenAccessLevel = (decode(data.access_token) as any)
          ?.accessLevel;

        queryClient.invalidateQueries('accessLevel');
        if (['junior', 'pleno'].includes(tokenAccessLevel)) {
          router.push('/dashboard/sales');
          return;
        }

        router.push('/dashboard');
      },
      onError: (error: AxiosError<any>) => {
        if (
          error?.response?.data?.error?.message ===
            'You need to update your password to continue' ||
          error?.response?.data?.message ===
            'You need to update your password to continue'
        ) {
          setTokenRef(error?.response?.data?.error?.token_ref);
          setEmailRef(methods.getValues('email'));

          router.push('/redefinir-senha');
          return;
        }
        toast({
          title: 'Erro ao fazer login',
          description: 'Verifique suas credenciais e tente novamente',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  const secondaryTextColor = useColorModeValue('gray.900', 'gray.50');

  return (
    <LoginLayout>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) => {
            login(data);
          })}
        >
          <Stack mb="8">
            <Heading
              fontSize="xl"
              color={secondaryTextColor}
            >
              Olá, tudo bem?
            </Heading>
            <Text
              fontSize="lg"
              color={secondaryTextColor}
              fontWeight={500}
            >
              Identifique-se para entrar em nosso Portal do Parceiro.
            </Text>
          </Stack>
          <Stack spacing="-1">
            <TextField
              name="email"
              label="E-mail"
            />

            <InputGroup>
              <PasswordField
                name="password"
                label="Senha"
              />
              <InputRightElement justifyContent="end">
                <Link
                  href="/forgot-password"
                  colorScheme="blue"
                  fontSize="sm"
                  fontWeight="600"
                  lineHeight="5"
                  textDecoration="underline"
                  whiteSpace="nowrap"
                  mb="4"
                >
                  Esqueceu a senha?
                </Link>
              </InputRightElement>
            </InputGroup>
          </Stack>
          <Button
            w="100%"
            variant="solid"
            type="submit"
            isLoading={isLoginLoading}
          >
            Entrar
          </Button>
        </form>
      </FormProvider>
    </LoginLayout>
  );
};

export default Home;
